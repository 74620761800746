import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import QRCode from "react-qr-code"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportLargeBodyClass, reportLargeHeadingClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  reportVariant: ReportVariant
}

export default class ReportSectionCongratulations extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionCongratulations"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.variant)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <Grid container className={classNames(styles.container, "same-page")}>
        {reportVariant === "pdf" && <Grid spacer spacerSize={16} />}
        <Grid sm={12}>
          <Typography
            component="h1"
            useContrastColor
            className={classNames(reportLargeHeadingClass, styles.congratulationsClass)}
          >
            {this.txt("congratulations_title")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography useContrastColor className={reportLargeBodyClass}>
            {this.txt("congratulations_body")}
          </Typography>
        </Grid>
        {reportVariant === "pdf" && (
          <>
            <Grid spacer spacerSize={16} />
            <Grid xs={12}>
              <div className={styles.qrContainer}>
                <QRCode className={styles.qrCode} value={window.location.href} />
              </div>
              <div className={styles.qrDescription}>
                <a href={window.location.href}>
                  <Typography useContrastColor className={reportBodyClass}>
                    {this.txt("qr_code")}
                  </Typography>
                </a>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    )
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    container: {
      marginBottom: "2rem"
    },
    congratulations: {
      // Allow title to go a little bit outside normal bounds for visual reasons
      marginRight: reportVariant === "mobile" ? 0 : "-4rem"
    },
    qrContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    qrCode: {
      height: "12rem"
    },
    qrDescription: {
      marginTop: "1rem",
      textAlign: "center"
    }
  })
}
