import type { TagCategory } from "app/surveys_admin/modules/state/model/Model"
import { Moment } from "moment"

import {
  Doc as BaseDoc,
  User as CoreUser,
  DocumentContainer,
  Link,
  TranslatedString
} from "core/modules/state/model/Model"

/**
 * Data model.
 */
export interface Model {
  documents: Documents
  views: Views
}

export interface Doc extends BaseDoc {
  created_at?: Moment
  modified_at?: Moment
}

/**
 * All documents by document type
 */
export interface Documents {
  OpenLinkSurvey: DocumentContainer<PlannedSurvey>
  Consent: DocumentContainer<Consent>
  Invite: DocumentContainer<Invite>
  Item: DocumentContainer<Item>
  Media: DocumentContainer<Media>
  Organisation: DocumentContainer<Organisation>
  PlannedSurvey: DocumentContainer<PlannedSurvey>
  Question: DocumentContainer<Question>
  QuestionOption: DocumentContainer<QuestionOption>
  Request: DocumentContainer<Request>
  RequestAction: DocumentContainer<RequestAction>
  Survey: DocumentContainer<Survey>
  SurveyFeedbackType: DocumentContainer<SurveyFeedbackType>
  SurveyResult: DocumentContainer<SurveyResult>
  Tag: DocumentContainer<Tag>
  User: DocumentContainer<User>
  SurveyState: DocumentContainer<SurveyState>
}

/**
 * Views that link to documents.
 */
export interface Views {}

// *** Document definitions ***

export const openLinkSurveyType = "OpenLinkSurvey"

// For survey
export interface Answer {
  category?: TranslatedString
  category_description?: TranslatedString
  options?: QuestionOption[]
  question_id?: number
  question_key?: string
  question_meta?: Record<string, any>
  title?: TranslatedString
}

export interface CompactTeam extends Doc {
  name: string
  parent?: CompactTeam
}

export interface Consent extends Doc {
  created_at: Moment
  consent_type: string
  scope: string
  organisation_survey_id: number
}

export interface Item extends Doc {
  url: string
  kind: DocumentType
  entry: Doc
}

export type InviteStatus = "new" | "existing"
export interface Invite extends Doc {
  user: Link<User>
  status: InviteStatus
  email: string
}

export type MediaType = "image"
export interface Media extends Doc {
  url: string
  type: MediaType
  image_url: string // Example https://example.com/path/to/image/{size}.png, {size} - possible size of image(could be 192, 480, 1080)
  content_url: string // URL on original file
  notes: string
  created_at: Moment
}

export interface Organisation extends Doc {
  url: string
  name: string
  max_users?: number
  activated_users?: number
  invited_users?: number
  logo?: string
}

export interface PlannedSurvey extends Doc {
  organisation_survey: ScheduledSurvey
  survey: Link<Survey>
  title?: string
  url: string
  last_completed?: Moment
  last_started?: Moment
}

export interface ScheduledSurvey {
  title: string
  ends_on: Moment
  started_at: Moment
  id?: number
  // A list of enabled features for this survey. Not to be confused with the Tag objects
  organisation_survey_tags: string[]
  teams?: CompactTeam[]
  report_custom_message: TranslatedString
  // A list of tags selectable by the user in this survey
  enabled_tags: Tag[]
  enabled_tag_categories: TagCategory[]
  allowed_domains?: string
  custom_welcome: TranslatedString
  custom_logo: string
}

export interface ReportExtra {
  reportMyGoals: ReportMyGoals
}

export interface ReportMyGoals {
  highLevelGoal: string
  myGoal: string
  myActions_1: string
  myActions_2: string
  myActions_3: string
  obstacles: string
}

// For survey
export type QuestionKind =
  | "bmi"
  | "field"
  | "star"
  | "boolean"
  | "checkbox"
  | "radio"
  | "dropdown"
  | "ordereddropdown"
  | "gas"

export type ReportKey =
  | "hintsa"
  | "wellbeing_test"
  | "wellbeing_start"
  | "wellbeing_end"
  | "wellbeing_general"
  | "wellbeing_pulse"
  | string

export interface Question extends Doc {
  title: TranslatedString
  description?: TranslatedString
  options: QuestionOption[]
  min_required_options?: number
  max_required_options?: number
  kind: QuestionKind
  notable: boolean
  key?: string
  category?: TranslatedString
  category_description?: TranslatedString
}

export interface QuestionOption extends Doc {
  conclusion?: TranslatedString
  icon_url?: string // Example https://example.com/path/to/image/{size}.png, {size} - possible size of image(could be 48x48, 64x64, 72x72, 80x80, 96x96, 120x120, 144x144, 192x192)
  note?: string
  points?: number
  title?: TranslatedString
}

export const surveyStateType = "SurveyState"
export interface SurveyState extends Doc {
  surveyId?: string
  surveyResultId?: string
  plannedSurveyId?: string
  openLinkSurveyId?: string
  lastAnsweredQuestionIndex?: number
  maxPage?: number
  initialTermsOfServiceConsentGiven?: boolean
  initialPrivacyPolicyConsentGiven?: boolean
  loginType?: "email" | "companyCode" | "openLink"
  companyCode?: string
  firstName?: string
  lastName?: string
  weight?: number
  height?: number
  email?: string
  country?: string
  selectedTeam?: string
  selectedTags?: string[]
  birthYear?: Date | null
  emailToken?: string
}

export type RequestActionType = "accept" | "reject"
// Request actions have no id, are always inlined and do not inherit from Document.
export interface RequestAction extends Doc {
  title: string
  url: string
  type: RequestActionType
}

export interface Survey extends Doc {
  title: TranslatedString
  description?: TranslatedString
  questions: Question[]
  icon_url: string // Template URL 'https://example.com/path/to/image/{size}.png', where '{size}' value can be from this list ['30x30', '34x34', '48x48', '64x64, '72x72', '80x80', '96x96', '120x120', '144x144', '192x192'],
  planned_icon_url: string // Template URL 'https://example.com/path/to/image/{size}.png', where '{size}' value can be from this list ['30x30', '34x34', '48x48', '64x64, '72x72', '80x80', '96x96', '120x120', '144x144', '192x192'],
  report_custom_message?: TranslatedString
  report_key: string
  custom_welcome: TranslatedString
  custom_logo: string
}

export type SurveyFeedbackType = "per_question" | "subtotal" | "total"
export interface SurveyFeedback extends Doc {
  type: SurveyFeedbackType
  conclusion: string
  icon_url: string // Example https://example.com/path/to/image/{size}.png, {size} - possible size of image(could be 48x48, 64x64, 72x72, 80x80, 96x96, 120x120, 144x144, 192x192),
  program_ids: number[]
}

export interface SurveyAnswerInstance extends Doc {
  answers?: Answer[]
  date: Moment
  title: string
  completed?: boolean
}

export interface SurveyResult extends Doc {
  created_at?: Moment
  id: string
  instances: SurveyAnswerInstance[]
  report_extra?: ReportExtra
  report_key?: string
  survey?: Link<Survey>
  // This was left inline for now but could be a link to a document
  scheduled_survey?: ScheduledSurvey
  user?: Link<User>
  uuid?: string
}

export const enum OrganisationSurveyTag {
  OpenLinkSurvey = "open_link_survey",
  SelectTeam = "select_team",
  AllowSelectingParentTeam = "allow_selecting_parent_team",
  AskUserName = "ask_user_name",
  AskUserEmail = "ask_user_email",
  AskUserCountry = "ask_user_country",
  AskUserBirthYear = "ask_user_birth_year",
  AskUserHeight = "ask_user_height",
  AskUserWeight = "ask_user_weight",
  SelectTags = "select_tags",
  AnonymousSurvey = "anonymous_survey"
}

export interface Tag extends Doc {
  id: string
  name: string
  name_translations?: TranslatedString
  category_name?: string
  category_name_translations?: TranslatedString
}

export type UserMeasurementUnits = "metric" | "imperial"
export type UserGender = "male" | "female"
export interface User extends CoreUser {
  first_name: string
  last_name?: string
  birthday?: Moment
  country?: string
  city?: string
  url: string
  created_at: Moment
  avatar_url: string // Template URL 'https://example.com/path/to/image/{size}.png', where '{size}' value can be from this list ['30x30', '34x34', '48x48', '64x64, '72x72', '80x80', '96x96', '120x120', '144x144', '192x192'],
  locale: string
  gender?: UserGender
  measurement_units: UserMeasurementUnits
  items_url: string
}

export interface Team extends CompactTeam {
  avatar_url?: string
  description?: string
  membership_count: number
}
