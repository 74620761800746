import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Button from "lib/ui/components/form/Button"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportSmallHeadingClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  reportVariant: ReportVariant
  onDownload: () => void
}

export default class ReportSectionFooter extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionFooter"]
  }

  constructor(props) {
    super(props)

    setupStyles()
  }

  render() {
    const { reportVariant, onDownload } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid container>
          <Grid spacer spacerSize={reportVariant === "mobile" ? 2 : 6} />
          <Grid xs={12} md={3}></Grid>
          <Grid xs={12} md={6}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.heading1)}>
              {this.txt("heading1")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.heading2)}>
              {this.txt("heading2")}
            </Typography>
            <Typography useContrastColor className={classNames(reportBodyClass, styles.content)}>
              {this.txt("content1")}
            </Typography>
            <Typography useContrastColor className={classNames(reportBodyClass, styles.content)}>
              {this.txt("content2")}
            </Typography>
            <div className={styles.centered}>
              <Button variant="secondary" onClick={onDownload} className={styles.button}>
                {this.txt("download_report")}
              </Button>
            </div>
          </Grid>
          <Grid xs={12} md={3}></Grid>
          <Grid spacer spacerSize={6} />
        </Grid>
      </>
    )
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    heading1: {
      textAlign: "center",
      marginBottom: "0rem"
    },
    heading2: {
      marginTop: "0.5rem",
      textAlign: "center"
    },
    content: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center"
    },
    centered: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      width: csx.important("unset"),
      marginTop: csx.important("3rem"),
      color: csx.important(ReportSectionFooter.theme.colors.fontLightColor.toString()),
      background: csx.important("transparent"),
      borderColor: csx.important(ReportSectionFooter.theme.colors.fontLightColor.toString()),
      borderWidth: csx.important("3px"),
      height: csx.important("3rem"),
      paddingLeft: csx.important("2rem"),
      paddingRight: csx.important("2rem")
    }
  })
}
